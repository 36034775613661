


























































































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import SiteLocationsModule from '@/store/modules/SiteLocations/module';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import { SiteLocation, Location, AddEditSitePayload, VerifiedSiteLocation } from '@/Model/siteLocations/types';
import APP_UTILITIES from '@/utilities/commonFunctions';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListOptions, DropdownListItem } from '@/ui-components/dropdownListBx/types';
import CancelConfirmation from '@/popupcomponents/cancelConfirmation/CancelConfirmation.vue';

@Component({
  components: {
    OffCanvas,
    ProgressButton,
    DropdownList,
    CancelConfirmation
  }
})
export default class AddEditSite extends Vue {
  @PropSync('isEditMode', { type: Boolean, required: true }) isEditModeSync !: boolean;
  @PropSync('isAddSiteVisible', { type: Boolean, required: true }) isAddSiteVisibleSync !: boolean;
  @Prop() selectedSite !: VerifiedSiteLocation;


  screenText = new ScreenText();

  private states: any = APP_UTILITIES.removeStates(
    APP_UTILITIES.getStatesShort(APP_CONST.COUNTRY_ID),
    APP_CONST.REMOVED_STATES
  );

  forceNumberInput = APP_UTILITIES.forceNumberInput;
  formValidations = {
    siteLocationName: {
      name: 'siteLocationName',
      error: false,
      errorText: 'Site Name is required'
    }
  };
  isValidForProgressButton = true;
  isCancelConfirmationVisible = false;
  siteLocationsModule = SiteLocationsModule;

  siteLocation: SiteLocation = {
    id: 1,
    siteLocationName: '',
    location: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      zip: ''
    } as Location
  };

  // This should be handled by a form validation library
  isSiteNameDirty = false;
  isAddressDirty = false;

  get formTitle(): string {
    const textName = this.isEditModeSync ? "EDIT_SITE_TITLE" : "ADD_SITE_TITLE";
    return this.screenText.getScreenText(textName);
  }

  get siteLocationInformation() {
    if (this.isEditModeSync) {
      this.siteLocation.id = this.selectedSite.id;
      this.siteLocation.siteLocationName = this.selectedSite.siteLocationName;
      if (this.selectedSite.location) {
        this.siteLocation.location.addressLine1 = this.selectedSite.location.addressLine1;
        this.siteLocation.location.addressLine2 = this.selectedSite.location.addressLine2;
        this.siteLocation.location.city = this.selectedSite.location.city;
        this.siteLocation.location.zip = this.selectedSite.location.zip5;
        this.siteLocation.location.stateCode = this.selectedSite.location.stateCode;
      }
    }
    return this.siteLocation
  }

  /**
   * Gets the dropdown options for the state dropdown.
   * @returns {DropdownListOptions} The dropdown options for the state dropdown.
   */
  get stateDropdownOptions(): DropdownListOptions {
    const { siteLocationInformation: { location } } = this;
    const selectedState = this.getStateByCode(location.stateCode);

    return {
      showSelectLabel: true,
      label: '',
      id: selectedState ? selectedState.id : 0,
      value: selectedState ? selectedState.state_code : 'Select',
      disable: false,
      search: false,
      error: false,
      singleSelect: true,
      dropdownList: this.states
        .sort()
        .map(
          (x: any) => ({
            id: x.id,
            value: x.state_code
          } as DropdownListItem)
        )
    }
  };


  /**
   * Retrieves the state object based on the provided state code.
   * @param stateCode - The code of the state to retrieve.
   * @returns The state object if found, otherwise undefined.
   */
  getStateByCode(stateCode: string): any | undefined {
    if (!stateCode) {
      return;
    }
    return this.states.find((state: any) => state.state_code === stateCode);
  }

  onClose() {
    this.resetData();
    this.isAddSiteVisibleSync = false;
    this.isEditModeSync = false;
  }

  onBeforeClose(event: Event) {
      if(this.hasEditChanges()){
        this.isCancelConfirmationVisible = true;
        event.preventDefault();
      }

      if (this.siteLocationsModule.isSavingSites && !this.isEditModeSync) {
        event.preventDefault();
      }
      else if ((this.hasSiteName() || this.hasAddress()) && !this.isEditModeSync) {
        this.isCancelConfirmationVisible = true;
        event.preventDefault();
      }
  }

  onSelectState(state: DropdownListItem) {
    if (state.id > 0) {
      this.siteLocation.location.stateCode = state.value;
    }
    else {
      this.siteLocation.location.stateCode = '';
    }
  }

  hasAddress(): boolean {
    const hasAddress = this.siteLocation.location.addressLine1.length > 0 ||
      this.siteLocation.location.addressLine2.length > 0 ||
      this.siteLocation.location.city.length > 0 ||
      this.siteLocation.location.stateCode.length > 0 ||
      this.siteLocation.location.zip.length > 0;
    if (hasAddress && this.isAddressDirty === false) {
      this.isAddressDirty = true;
    }
    return hasAddress;
  }

  hasEditChanges(): boolean {
	if(this.isEditModeSync) {
		const hasEditChanges =
		  this.siteLocationInformation.siteLocationName.length != this.selectedSite.siteLocationName.length
		  ||
		  (
			this.siteLocationInformation.location &&
			this.siteLocationInformation.location.addressLine1.length != this.selectedSite.location.addressLine1.length ||
			this.siteLocationInformation.location.addressLine2.length != this.selectedSite.location.addressLine2.length ||
			this.siteLocationInformation.location.city.length != this.selectedSite.location.city.length ||
			this.siteLocationInformation.location.zip.length != this.selectedSite.location.zip5.length
		  );
		  return hasEditChanges;
	}
	return false;
}

  hasSiteName(): boolean {
    const hasSiteName = this.siteLocation.siteLocationName.length > 0;
    if (hasSiteName && this.isSiteNameDirty === false) {
      this.isSiteNameDirty = true;
    }
    return hasSiteName;
  }

  isSiteNameValid(): boolean {
    const checkValidation = APP_UTILITIES.requiredFields(this.siteLocation, this.formValidations, true);
    return checkValidation.validateData.siteLocationName.error === false;
  }

  isAddressValid(): boolean {
    return true; // TODO: fill when hooked up to service
  }

  isSaveEnabled(): boolean {
    return this.hasSiteName() &&
      this.isSiteNameValid() &&
      (
        this.hasAddress() === false ||
        (this.hasAddress() && this.isAddressValid())
      );
  }

  onCancel() {
    this.isAddSiteVisibleSync = false;

  }
  async onSubmit() {
    const isValid = this.isSaveEnabled();
    // TODO: (REFACTOR) This should really get pulled from the Store modules (current it's not kept up-to-date properly)
    const accountIdCookieValue = APP_UTILITIES.getCookie('accountId');
    if (isValid && accountIdCookieValue) {
      const shouldCreateLocation = this.hasAddress() && this.isAddressValid()
      const accountId = +accountIdCookieValue;
      const payload = {
          newSite: this.siteLocationInformation,
          accountId,
          shouldCreateLocation
        } as AddEditSitePayload;

      this.isValidForProgressButton = false;
      if (this.isEditModeSync) {
        await this.siteLocationsModule.updateSite(payload);
      } else {
        await this.siteLocationsModule.addNewSite(payload);
      }
      this.isValidForProgressButton = true;

      if (this.siteLocationsModule.didSitesSaveFail === false) {
        this.resetData();
        this.isAddSiteVisibleSync = false;
        this.isEditModeSync = false;
        this.$emit('onSiteLocationDataModified');
      }
    }
  }

  translateLoadingToProgressButtonState(): number {
    let buttonState = 0;

    if (this.siteLocationsModule.isSavingSites === false && this.siteLocationsModule.didSitesSaveFail === false) {
      buttonState = 200;
    }
    else if (this.siteLocationsModule.isSavingSites === false && this.siteLocationsModule.didSitesSaveFail) {
      buttonState = 400;
    }
    return buttonState;
  }

  resetData() {
    this.siteLocation.siteLocationName = '';
    this.siteLocation.location.addressLine1 = '';
    this.siteLocation.location.addressLine2 = '';
    this.siteLocation.location.city = '';
    this.siteLocation.location.stateCode = '';
    this.siteLocation.location.zip = '';
    this.isSiteNameDirty = false;
    this.isAddressDirty = false;
    this.siteLocationsModule.resetNewSite();
  }

  onContinueConfirmation() {
    this.isCancelConfirmationVisible = false;
    this.onClose();
  }
}
